import { module as modu } from 'modujs';
// https://codepen.io/p_swies/pen/NWRzoXO?editors=1010


export default class extends modu {
    constructor(m) {
        super(m);

        this.$box = this.$('box');
        this.$wrapper = this.$('wrapper')[0]

        // Binding
        this.onResizeBind = this.onResize.bind(this);
    }

    bindEvents() {
      window.addEventListener('resize', this.onResizeBind);
    }

    unbindEvents() {
      window.removeEventListener('resize', this.onResizeBind);
    }

    onResize() {
      const half_length = Math.ceil(this.$box.length / 2);
      const leftSize = Array.from(this.$box).slice(0, half_length);
      const rightSize = Array.from(this.$box).slice(half_length, this.$box.length);

      this.$wrapper.style.maxHeight = null;

      if (window.matchMedia("(min-width: 768px)").matches) {
        const leftHeight = leftSize.reduce(
          (accumulator, current) => accumulator + current.clientHeight,
          0
        );

        const rightHeight = rightSize.reduce(
          (accumulator, current) => accumulator + current.clientHeight,
          0
        );

        this.$wrapper.style.maxHeight = `${Math.max(leftHeight, rightHeight)}px`;
      }
    }

    init() {
      this.bindEvents();
      this.onResize();
    }

    destroy() {
      super.destroy()
      this.unbindEvents()
    }
}
