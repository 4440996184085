import { module as modu } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends modu {
    constructor(m) {
        super(m);
    }

    init() {
      this.scroll = new LocomotiveScroll({
        el: this.el,
        smooth: true,
        reloadOnContextChange: true,

        smartphone: {
          smooth: false
        },

        tablet: {
          smooth: true
        },

      });

      this.scroll.on('call', (func, way, obj, id) => {
        // Using modularJS
        // console.log('func', func);
        // console.log('way', way);
        // console.log('obj', obj);
        // console.log('id', id);

        this.call(func[0], { way, obj }, func[1], func[2]);
      });
    }

    scrollTo(elm) {
      this.scroll.scrollTo(elm);
    }

    refresh() {
      console.log('refresh scroll');
      this.scroll.update();
    }

    destroy() {
      this.scroll.destroy();
    }
}