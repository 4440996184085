import { module as modu } from 'modujs';
import { html } from '../utils/environment';

export default class extends modu {
    constructor(m) {
        super(m);
    }

    init() {
      const links = this.el.querySelectorAll('a');

      links.forEach(item => {
        const anchor = item.dataset.anchor;

        item.addEventListener('click', event => {
          //handle click

          const isHome = html.dataset.page === 'frontpage';

          if( isHome && anchor.length ) {
	          event.preventDefault();
            this.call('scrollTo', `#${anchor}`, 'Scroll');
            event.preventDefault();
          }
        })
      });
    }
}
