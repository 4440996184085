import { module as modu } from 'modujs';
import { html } from '../utils/environment';

export default class extends modu {
    constructor(m) {
        super(m);

        console.log('yo');
        console.log(this.el);
        this.$el = this.el;
        this.$button =  this.el.querySelector('button');

        // Binding
        this.onClickBind = this.toggle.bind(this)
    }

    init() {
        console.log('mobile nav');
        this.bindEvents();
    }

    toggle() {
        this.$el.classList.toggle('menu-open');
    }

    off() {
        this.$el.classList.remove('menu-open');
    }

    bindEvents() {
        this.$button.addEventListener('click', this.onClickBind);
      }

    unbindEvents() {
    this.$button.removeEventListener('click', this.onClickBind);
    }

    destroy() {
        this.unbindEvents();
      }
}
