import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import MicroModal  from 'micromodal';

import '../functions.js'

export default function() {
  Splitting();
  MicroModal.init();
}