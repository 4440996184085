import { module as modu } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';
import globals from '../globals';

export default class extends modu {
    constructor(m) {
        super(m);
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 1000,
            readyClass: 'is-load-ready',
            transitions: {
                customTransition: {}
            }
        });

        this.load.on('loading', (transition, oldContainer) => {

          if(document.activeElement) document.activeElement.blur()
          html.classList.remove('is-ready')
        });

        this.load.on('ready', () => {

          setTimeout(() => {
            html.classList.add('is-ready');
            this.call('refresh', null, 'Scroll');
          }, 300);
        });

        this.load.on('images', () => {
          setTimeout(() => {
            this.call('refresh', null, 'Scroll');
            console.log('load images..');
          }, 300);
        });

        this.load.on('loaded', async (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            globals();
            this.wholeClickable();

            this.call('off', null, 'MobileNav');
        });

        this.wholeClickable();
    }

    wholeClickable() {
      const cards = Array.from(document.querySelectorAll('.is-whole-clickable'));
      for (const card of cards) {
        const link = card.querySelector('a').getAttribute('href');
        (card).onclick = (e) => {
          this.load.goTo(link)
        }
      }
    }
}
