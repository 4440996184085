import { module as modu } from 'modujs';
// https://codepen.io/p_swies/pen/NWRzoXO?editors=1010


export default class extends modu {
    constructor(m) {
        super(m);

        this.events = {
          click: {
              header: 'toggleSection'
          }
        }
    }

    init() {
      this.setHeight(this.$('section.is-open')[0]);
    }

    setHeight(target){
      target.querySelector('.accordion_body').style.maxHeight = `${target.querySelector('.accordion_body > div').clientHeight}px`;
    }

    toggleSection(e) {
      const target = e.curTarget;
      const section = this.parent('section', target);

      if (section.classList.contains('is-open')) {
        // section.classList.remove('is-open');
        return;

      } else {
        if (this.$('section.is-open').length) {
          this.$('section.is-open')[0].querySelector('.accordion_body').style.maxHeight = '';
          this.$('section.is-open')[0].classList.remove('is-open');
        }

        section.classList.add('is-open');

        this.setHeight(section);
        this.call('scrollTo', {target: section}, 'Scroll');
      }

      setTimeout(() => {
        this.call('refresh', 'Scroll');
      }, 100);

    }
}
