import { module as modu } from 'modujs';
import emblaCarousel from 'embla-carousel';

export default class extends modu {
    constructor(m) {
        super(m);
    }

    init() {
      this.embla = emblaCarousel(this.$('viewport')[0], {
        align: "start",
        loop: true,
        skipSnaps: false,
      });

      this.embla.on("init", () => this.call('refresh', null, 'Scroll'));

      this.el.querySelector('.-prev').addEventListener('click', this.embla.scrollPrev, false);
      this.el.querySelector('.-next').addEventListener('click', this.embla.scrollNext, false);
    }
}
