import { module  as modu } from 'modujs';

export default class extends modu {
    constructor(m) {
        super(m);
    }

    submit(e) {

      const xhr = new XMLHttpRequest();
      const formData = new FormData(this.form);

      this.el.querySelector('.c-form')?.classList.add('btn-is-sending');

      xhr.addEventListener( "load", (event) => {
        this.form.reset();
        this.el.querySelector('.c-form')?.classList.remove('btn-is-sending');
        this.el.querySelector('.c-form')?.classList.add('btn-is-completed');

        setTimeout( () => this.el.querySelector('.c-form')?.classList.remove('btn-is-completed'), 3550);

      });

      // Define what happens in case of error
      xhr.addEventListener( "error", ( event ) => {
        alert( 'Oops! Something went wrong.' );
      } );

      xhr.open( "POST", settings.ajaxurl );
      formData.append('action', this.form.id);
      xhr.send( formData );
    }

    scrollNext(e) {
      e.preventDefault();
      this.submit();
    }


    init() {
      this.button = this.el.querySelector('button');
      this.form = this.el.querySelector('form');

      const elements = this.form.elements;

      for (let i=0; i < elements.length; i++){
        elements[i].addEventListener( "focus", (e) => {
          e.target.closest("p").classList.add('has-focus');
        });

        elements[i].addEventListener( "focusout", (e) => {
          e.target.closest("p").classList.remove('has-focus');
        });

        elements[i].addEventListener( "blur", (e) => {
          if(e.target.value){
            e.target.closest("p").classList.add('has-value');
          } else {
            e.target.closest("p").classList.remove('has-value');
          }
        });
      }

      this.button.addEventListener('click', (e) => this.scrollNext(e), false);
    }

}